//Angular
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  IMAGE_LOADER,
  ImageLoaderConfig,
  NgOptimizedImage
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Appkit
import { BadgeModule } from '@appkit4/angular-components/badge';
import { BreadcrumbModule } from '@appkit4/angular-components/breadcrumb';
import { ButtonModule } from '@appkit4/angular-components/button';
import { CalendarModule } from '@appkit4/angular-components/calendar';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { ComboboxModule } from '@appkit4/angular-components/combobox';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { DropdownListItemModule } from '@appkit4/angular-components/dropdown-list-item';
import { FieldModule } from '@appkit4/angular-components/field';
import { ModalModule } from '@appkit4/angular-components/modal';
import { MultipleDropdownModule } from '@appkit4/angular-components/multiple-dropdown';
import { NotificationModule } from '@appkit4/angular-components/notification';
import { SearchModule } from '@appkit4/angular-components/search';
import { SingleDropdownModule } from '@appkit4/angular-components/single-dropdown';
import { TabsModule } from '@appkit4/angular-components/tabs';
import { TagModule } from '@appkit4/angular-components/tag';

// FDM
import { AlertBarComponent } from './components/alert-bar/alert-bar.component';
import { BannerComponent } from './components/banner/banner.component';
import { ConfirmationModalComponent } from './dialogues/confirmation-modal/confirmation-modal.component';

// Kendo UI
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { FilterModule } from '@progress/kendo-angular-filter';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FilterDateRangeComponent } from './components/filter-date-range/filter-date-range.component';
import { FilterDateTimePickerComponent } from './components/filter-date-time-picker/filter-date-time-picker.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { NotificationComponent } from './components/notification/notification.component';

// Angular Formatting Pipes
import { FdmDatePipe } from './formatting/fdm-date.pipe';
import { FdmNumberPipe } from './formatting/fdm-number.pipe';
import { FdmPercentagePipe } from './formatting/fdm-percentage.pipe';

@NgModule({
  declarations: [
    AlertBarComponent,
    BannerComponent,
    ConfirmationModalComponent,
    FilterDateTimePickerComponent,
    NotificationComponent,

    // Pipes
    FdmNumberPipe,
    FdmDatePipe,
    FdmPercentagePipe,

    FilterDateRangeComponent,
    FilterDropdownComponent,
    NotificationComponent
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage,
    DecimalPipe,
    DatePipe,

    // Appkit
    ButtonModule,
    FieldModule,
    SingleDropdownModule,
    DropdownListItemModule,
    ModalModule,
    BadgeModule,
    SearchModule,
    TabsModule,
    BreadcrumbModule,
    CalendarModule,
    DropdownModule,
    MultipleDropdownModule,
    NotificationModule,
    CheckboxModule,
    ComboboxModule,
    TagModule,

    // Kendo UI
    ButtonsModule,
    DateInputsModule,
    DropDownsModule,
    ExcelExportModule,
    FormsModule,
    GridModule,
    IconsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    NavigationModule,
    DropDownListModule,
    ExcelModule,
    PDFModule,
    PopupModule,
    FloatingLabelModule,
    WindowModule,
    FilterModule,
    IndicatorsModule,
    DialogsModule
  ],
  exports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgOptimizedImage,

    // Appkit
    ButtonModule,
    FieldModule,
    SingleDropdownModule,
    DropdownListItemModule,
    ModalModule,
    BadgeModule,
    SearchModule,
    TabsModule,
    BreadcrumbModule,
    CalendarModule,
    DropdownModule,
    MultipleDropdownModule,
    NotificationModule,
    CheckboxModule,
    ComboboxModule,
    TagModule,

    // Kendo UI
    ButtonsModule,
    DateInputsModule,
    DropDownsModule,
    ExcelExportModule,
    FormsModule,
    GridModule,
    IconsModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    NavigationModule,
    DropDownListModule,
    FilterDropdownComponent,
    FilterDateRangeComponent,
    FilterDateTimePickerComponent,
    NotificationComponent,
    ExcelModule,
    PDFModule,
    PopupModule,
    FloatingLabelModule,
    WindowModule,
    FilterModule,
    IndicatorsModule,
    DialogsModule,

    // Pipes
    FdmNumberPipe,
    FdmDatePipe,
    FdmPercentagePipe,

    // FDM
    AlertBarComponent,
    BannerComponent,
    ConfirmationModalComponent
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `assets/images/${config.src}`;
      }
    }
  ]
})
export class SharedModule {}
